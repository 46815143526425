import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LanguageService } from './services/language.service';
import { SessionToken } from './components/verify-email/verify-email.component';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isLandingPage = false;
  state: string;
  sessionData: SessionToken | null = null;

  constructor(
    translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
    private renderer: Renderer2
  ) {
    // Available languages
    translate.addLangs(['de', 'fr']);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');
    // Check localStorage for saved language
    this.languageService.initLanguage();
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          this.router.url === '/about-fenaco-login' ||
          this.router.url === '/imprint' ||
          this.router.url === '/privacy'
        ) {
          this.isLandingPage = true;
        } else {
          this.isLandingPage = false;
        }
      }
    });

    this.route.queryParams.subscribe((params) => {
      const sessionToken = params['session_token'];
      if (sessionToken) {
        this.state = params['state'];
        this.sessionData = this.decodeToken(sessionToken);
      }
      this.languageService.setLanguage(
        this.sessionData?.locale || this.languageService.getCurrentLanguage()
      );
    });

    this.checkScreenSizeAndScroll();
  }

  decodeToken(token: string) {
    try {
      return jwtDecode<SessionToken>(token);
    } catch (Error) {
      return null;
    }
  }

  @HostListener('window:scroll', ['$event'])
  checkScreenSizeAndScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const element = document.getElementById('#navbar');
    const section = document.getElementById('#scroll-section');

    // Add or remove the class based on scroll position
    if (element && section) {
      if (scrollPosition > 0) {
        this.renderer.addClass(element, 'scrolled-down');
        const height = element.getBoundingClientRect().height;
        section.style.paddingTop = `${height}px`;
      } else {
        this.renderer.removeClass(element, 'scrolled-down');
        section.style.paddingTop = '0px';
      }
    }
  }
}
