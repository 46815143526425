import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '../../data.service';
import { environment } from '../../../environments/environment';
import { LanguageService } from 'src/app/services/language.service';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';

type UserProfile = {
  firstName: string;
  lastName: string;
  locale: string;
  salutation: string;
};

@Component({
  selector: 'app-profile-content',
  templateUrl: './profile-content.component.html',
  styleUrls: ['./profile-content.component.css'],
})
export class ProfileContentComponent extends Component implements OnInit {
  @Input() user: User; // The type should match the expected data type
  userProfile: UserProfile = null;
  editProfileForm: FormGroup;
  isLoading = false;
  formData = {
    salutation: '',
    firstName: '',
    lastName: '',
    locale: '',
  };
  owner$: Observable<boolean>;

  constructor(
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private router: Router,
    private languageService: LanguageService,
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    super({});
    this.translate = translate;
    this.owner$ = this.auth.user$.pipe(
      filter((user) => user !== null), // Filter out null values if needed
      map((user) => user['https://www.fenaco.com/poc/roles'].includes('Owner'))
    );
  }

  saveChanges(form: NgForm) {
    this.isLoading = true;

    // Handle saving changes
    if (form.valid) {
      // Proceed with saving changes
      // send post request to update profile
      const formData = form.value;
      this.dataService
        .postData(environment.updatePorfileEndpoint, formData)
        .subscribe({
          next: (response) => {
            this.translate
              .get('editProfile.feedbacks.profileUpdated')
              .subscribe((translatedFeedback: string) => {
                this._snackBar.open(translatedFeedback, '✖️', {
                  duration: 3000,
                  verticalPosition: 'top',
                  politeness: 'assertive',
                  panelClass: ['success-snackbar'],
                });
              });
            this.isLoading = false;
          },
          error: (error) => {
            this.translate
              .get('editProfile.feedbacks.profileUpdateFailed')
              .subscribe((translatedFeedback: string) => {
                this._snackBar.open(translatedFeedback, '✖️', {
                  duration: 3000,
                  verticalPosition: 'top',
                  politeness: 'assertive',
                  panelClass: ['error-snackbar'],
                });
              });
            this.isLoading = false;
          },
        });
    } else {
      if (
        form.controls.firstName.errors?.required &&
        form.controls.lastName.errors?.required
      ) {
        return this.translate
          .get('errors.firstName')
          .subscribe((translatedFeedback: string) => {
            this._snackBar.open(translatedFeedback, '✖️', {
              duration: 3000,
              verticalPosition: 'top',
              politeness: 'assertive',
              panelClass: ['error-snackbar'],
            });
          });
      }
      if (form.controls.lastName.errors?.required) {
        return this.translate
          .get('errors.lastName')
          .subscribe((translatedFeedback: string) => {
            this._snackBar.open(translatedFeedback, '✖️', {
              duration: 3000,
              verticalPosition: 'top',
              politeness: 'assertive',
              panelClass: ['error-snackbar'],
            });
          });
      }
      if (form.controls.firstName.errors?.required) {
        return this.translate
          .get('errors.firstName')
          .subscribe((translatedFeedback: string) => {
            this._snackBar.open(translatedFeedback, '✖️', {
              duration: 3000,
              verticalPosition: 'top',
              politeness: 'assertive',
              panelClass: ['error-snackbar'],
            });
          });
      }
      this.isLoading = false;
    }
    this.languageService.setLanguage(this.formData.locale);
  }

  ngOnInit() {
    this.userProfile = this.user['com.fenaco.id/profile'];
    if (this.userProfile) {
      this.formData = { ...this.userProfile };
      this.languageService.setLanguage(this.formData.locale);
    }
  }

  resetForm() {
    this.formData = { ...this.userProfile };
  }

  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }

  policy() {
    this.router.navigate(['/privacy']);
  }
}
