import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

export type SessionToken = {
  continue_uri: string;
  locale: string;
  email: string;
  isResend: boolean;
  landis: { id: string; name: string }[];
  exp: number;
  iat: number;
  ip: string;
  iss: string;
  sub: string;
  error?: string;
  backUrl?: string;
  backUrlText?: string;
  clientName?: string;
};

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
})
export class VerifyEmailComponent {
  sessionData: SessionToken | null = null;
  state: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private languageService: LanguageService
  ) {}
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const sessionToken = params['session_token']; // Replace 'myParam' with your parameter name
      if (!sessionToken) {
        this.router.navigate(['/error']);
      }

      this.state = params['state'];
      if (!this.state) {
        this.router.navigate(['/error']);
      }

      this.sessionData = this.decodeToken(sessionToken);
      this.languageService.setLanguage(
        this.sessionData.locale || this.languageService.getCurrentLanguage()
      );
    });
  }

  decodeToken(token: string) {
    try {
      return jwtDecode<SessionToken>(token);
    } catch (Error) {
      return null;
    }
  }

  continue() {
    window.location.href = `${this.sessionData?.continue_uri}?state=${this.state}`;
  }

  resend() {
    window.location.href = `${this.sessionData?.continue_uri}?state=${this.state}&resend=true`;
  }

  getLogo(): string {
    if (
      this.sessionData?.clientName === 'WSA' ||
      this.sessionData?.clientName === 'WSA-POC'
    ) {
      return '../../../assets/footer-icon-3.svg';
    } else if (
      this.sessionData?.clientName === 'ECS' ||
      this.sessionData?.clientName === 'AOP-POC'
    ) {
      return '../../../assets/agrola-logo.svg';
    } else {
      return 'https://d1zl99f7e5jysh.cloudfront.net/image/992382361685/image_segleuhno14kvbimin4o22570c/-FPNG%20-S1000x200';
    }
  }
}
