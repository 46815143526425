<app-alt-nav-bar></app-alt-nav-bar>
<section
  id="#scroll-section"
  class="w-screen flex flex-col justify-center items-center text-primary-default"
>
  <div class="w-full py-14 md:py-24">
    <div
      class="w-full flex flex-col gap-10 justify-center items-center sub-section-container"
    >
      <h2 class="pageHeader">{{ 'privacyPolicyPage.title' | translate }}</h2>
      <div
        class="flex flex-col w-full justify-center items-start text-content-container"
      >
        <h4 class="pageParagraph font-bold">
          {{ 'privacyPolicyPage.subTitleOne' | translate }}
        </h4>
        <p class="pageParagraph font-light">
          {{ 'privacyPolicyPage.paragraphOne' | translate }}
        </p>
        <ul>
          <li class="pageParagraph font-light">
            {{ 'privacyPolicyPage.unorderedListOne.listItemOne' | translate }}
          </li>
          <li class="pageParagraph font-light">
            {{ 'privacyPolicyPage.unorderedListOne.listItemTwo' | translate }}
          </li>
        </ul>
        <ol>
          <li class="pageParagraph font-light">
            {{ 'privacyPolicyPage.orderedListOne.listItemOne' | translate }}
          </li>
          <li class="pageParagraph font-light">
            {{ 'privacyPolicyPage.orderedListOne.listItemTwo' | translate }}
          </li>
        </ol>
        <a class="pageLink" href="/" target="_blank">{{
          'privacyPolicyPage.linkOne' | translate
        }}</a>
        <h4 class="pageParagraph font-bold">
          {{ 'privacyPolicyPage.subTitleTwo' | translate }}
        </h4>
        <p class="pageParagraph font-light">
          {{ 'privacyPolicyPage.paragraphTwo' | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
<app-end-footer></app-end-footer>
