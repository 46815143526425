<div
  class="flex max-h-full justify-center items-center"
  *ngIf="auth.isLoading$ | async; else loaded"
>
  <app-loading></app-loading>
</div>

<ng-template #loaded>
  <app-change-email-flow-content
    [user]="profile"
  ></app-change-email-flow-content>
</ng-template>
