<div
  id="#navbar"
  class="border-b-[1px] border-b-gray300 p-2 py-4 w-full transition-all bg-white"
>
  <div
    class="content-max-width flex flex-row w-full justify-between gap-y-1 gap-x-2 sm:gap-4 flex-wrap"
  >
    <div
      *ngIf="
        this.sessionData &&
        this.sessionData?.backUrl &&
        this.sessionData?.backUrlText
      "
      class="flex w-fit text-center justify-center items-center mr-auto p-2"
    >
      <a class="navbar-back" href="{{ sessionData.backUrl }}">
        <img
          src="../../assets/arrow-back-green.svg"
          alt="&laquo;"
          class="w-[40px] sm:w-[50px]"
        />
        <span class="sm:hidden">{{
          'navbar.goBackUrlMobile' | translate
        }}</span>
        <span
          class="hidden sm:inline"
          [translate]="'navbar.goBackUrl'"
          [translateParams]="{ backUrlText: sessionData.backUrlText }"
        >
        </span>
      </a>
    </div>
    <div class="flex items-center justify-center ml-auto">
      <a routerLink="/">
        <picture>
          <source
            srcset="
              https://d1zl99f7e5jysh.cloudfront.net/image/992382361685/image_segleuhno14kvbimin4o22570c/-FPNG%20-S1000x200
            "
            media="(min-width: 640px)"
          />
          <img
            src="../../assets/fenaco-logo-mobile.svg"
            alt="Fenaco logo"
            class="max-h-[42px]"
          />
        </picture>
      </a>
    </div>
  </div>
</div>
