import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about-fenaco-id',
  templateUrl: './about-fenaco-id.component.html',
  styleUrls: ['./about-fenaco-id.component.css'],
})
export class AboutFenacoIdComponent implements OnInit {
  constructor(public translate: TranslateService) {}

  scrollTop() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.scrollTop();
  }
}
