import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrl: './imprint.component.css',
})
export class ImprintComponent implements OnInit {
  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
