import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from 'jwt-decode';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SessionToken } from '../verify-email/verify-email.component';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements OnInit {
  isCollapsed = true;
  faUser = faUser;
  faPowerOff = faPowerOff;
  owner$: Observable<boolean>;
  state: string;
  sessionData: SessionToken | null = null;
  isDefaultRoute = false;

  constructor(
    public translate: TranslateService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const sessionToken = params['session_token'];
      if (sessionToken) {
        this.state = params['state'];
        this.sessionData = this.decodeToken(sessionToken);
      }
      this.languageService.setLanguage(
        this.sessionData?.locale || this.languageService.getCurrentLanguage()
      );
    });
  }

  decodeToken(token: string) {
    try {
      return jwtDecode<SessionToken>(token);
    } catch (Error) {
      return null;
    }
  }
}
