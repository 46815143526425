export const environment = {
  production: false,
  changePasswordEndpoint:
    'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api/change-password',
  updatePorfileEndpoint:
    'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api/update-profile',
  changeEmailEndpoint:
    'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api/change-email',
  changeEmailConfirmEndpoint:
    'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api/change-email-confirm',
  generateOtpEndpoint:
    'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api/generate-otp',
  confirmOtpEndpoint:
    'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api/confirm-otp',

  auth: {
    domain: 'id-dev.fenaco.com',
    clientId: 'bgnTvuATPv6KFrdAvkJVN2MiNivhj1Rk',

    authorizationParams: {
      audience: 'https://fenaco.com/default',
      redirect_uri: 'https://profile-dev.fenaco.com',
    },
    errorPath: '/error',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
