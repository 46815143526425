<!-- user-profile.component.html -->
<section id="#scroll-section" class="bg-appBgrd flex flex-col w-screen">
  <div
    class="flex flex-col lg:flex-row w-full gap-y-1 lg:gap-8 lg:justify-start justify-end p-4 lg:p-8"
  >
    <div
      class="flex flex-col w-full justify-center items-center lg:w-4/12 lg:justify-start lg:items-end"
    >
      <div
        class="flex lg:flex-col lg:w-[310px] lg:bg-white p-4 lg:p-8 gap-6 rounded-sm lg:border-solid lg:border-gray200 lg:border-[1px] flex-wrap items-center justify-center w-full"
      >
        <h4
          class="flex flex-row items-center gap-2 lg:gap-4 text-white text-base bg-fenacoGreen p-2 rounded-3xl lg:w-full"
        >
          <mat-icon>account_circle</mat-icon>
          <span>
            {{ 'userProfile.sideMenu.menuItem1' | translate }}
          </span>
        </h4>

        <button
          class="flex flex-row text-center justify-center lg:justify-start items-center gap-2 lg:gap-4 text-base flex-wrap lg:w-full text-gray600"
          style="min-width: unset"
          id="qsLogoutBtn"
          (click)="policy()"
          *ngIf="(auth.isAuthenticated$ | async) === true"
        >
          <mat-icon>lock</mat-icon>
          <span class="flex text-left">{{ 'navbar.policy' | translate }}</span>
        </button>

        <div
          class="hidden lg:block border-t-[1px] border-t-gray300 w-full"
        ></div>

        <button
          class="flex flex-row text-center justify-center lg:justify-start items-center gap-2 lg:gap-4 text-base flex-wrap leading-4 lg:w-full text-gray400"
          style="min-width: unset"
          id="qsLogoutBtn"
          (click)="logout()"
          *ngIf="(auth.isAuthenticated$ | async) === true"
        >
          <mat-icon class="rotate">logout</mat-icon>
          <span class="flex text-left max-w-[186px]">{{
            'navbar.logout' | translate
          }}</span>
        </button>
      </div>
    </div>
    <div
      class="flex flex-col gap-3 w-full justify-center items-center lg:w-8/12 lg:justify-start lg:items-start"
    >
      <form
        class="bg-transparent flex flex-col gap-3"
        #editProfileForm="ngForm"
        (ngSubmit)="saveChanges(editProfileForm)"
      >
        <div
          class="flex flex-col w-full lg:min-w-[420px] h-fit bg-white p-8 gap-y-1 md:gap-4 rounded-sm border-solid border-gray200 border-[1px]"
        >
          <div class="flex flex-col pb-6">
            <h1 class="text-gray600 text-2xl font-semibold">
              {{ 'editProfile.formTitle' | translate }}
            </h1>
            <p class="text-gray400 text-sm font-normal">
              {{ 'editProfile.formDescription' | translate }}
            </p>
          </div>
          <div class="flex flex-col gap-1">
            <div class="flex flex-col">
              <mat-form-field>
                <mat-label>{{
                  'editProfile.salutation' | translate
                }}</mat-label>
                <mat-select
                  id="salutation"
                  required="true"
                  [(ngModel)]="formData.salutation"
                  name="salutation"
                >
                  <mat-option value="" disabled>{{
                    'editProfile.selectSalutation' | translate
                  }}</mat-option>
                  <mat-option value="mr">{{
                    'editProfile.salutationOptions.mr' | translate
                  }}</mat-option>
                  <mat-option value="ms">{{
                    'editProfile.salutationOptions.ms' | translate
                  }}</mat-option>
                  <mat-option value="other">{{
                    'editProfile.salutationOptions.other' | translate
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ 'editProfile.firstName' | translate }}</mat-label>
                <input
                  matInput
                  id="firstName"
                  required="true"
                  type="text"
                  [placeholder]="'editProfile.firstName' | translate"
                  [(ngModel)]="formData.firstName"
                  name="firstName"
                />
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ 'editProfile.lastName' | translate }}</mat-label>
                <input
                  matInput
                  id="lastName"
                  required="true"
                  type="text"
                  [placeholder]="'editProfile.lastName' | translate"
                  [(ngModel)]="formData.lastName"
                  name="lastName"
                />
              </mat-form-field>
            </div>

            <mat-form-field class="w-full py-8">
              <mat-label>{{
                'editProfile.preferredLanguage' | translate
              }}</mat-label>
              <mat-select
                id="locale"
                required="true"
                name="locale"
                [(ngModel)]="formData.locale"
              >
                <mat-option value="" disabled>{{
                  'editProfile.selectLanguage' | translate
                }}</mat-option>
                <mat-option value="de">{{
                  'editProfile.languageOptions.de' | translate
                }}</mat-option>
                <mat-option value="fr">{{
                  'editProfile.languageOptions.fr' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="flex flex-col gap-1">
              <div class="flex flex-col gap-4">
                <div class="flex flex-col gap-1 w-full">
                  <p class="text-gray600 text-base font-normal">
                    {{
                      'editProfile.changeEmailAndPasswordSection' | translate
                    }}
                  </p>
                  <div class="flex flex-row gap-2">
                    <mat-icon class="formIcons">mail_outline</mat-icon>

                    <div
                      class="text-left w-full text-sm p-2 font-normal disabled:bg-white"
                    >
                      {{
                        user?.email.length > 20
                          ? user?.email.substring(0, 20) + '...'
                          : user?.email
                      }}
                    </div>

                    <div
                      class="flex flex-row gap-1 w-14 justify-center items-center"
                      href="#"
                    >
                      <a
                        class="text-[#334155] text-sm font-normal"
                        [routerLink]="['/change-email']"
                      >
                        {{ 'editProfile.changeLinkCaption' | translate }}
                      </a>
                      <mat-icon class="linkIcons">open_in_new</mat-icon>
                    </div>
                  </div>
                  <div class="flex flex-row gap-2">
                    <mat-icon class="formIcons">lock</mat-icon>

                    <div
                      class="text-left w-full text-base p-2 font-normal leading-7"
                    >
                      ••••••••
                    </div>

                    <div
                      class="flex flex-row gap-1 w-14 justify-center items-center"
                      href="#"
                    >
                      <a
                        class="text-[#334155] text-sm font-normal"
                        [routerLink]="['/change-password']"
                      >
                        {{ 'editProfile.changeLinkCaption' | translate }}
                      </a>
                      <mat-icon class="linkIcons">open_in_new</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex w-full gap-2 justify-center flex-col-reverse flex-wrap lg:flex-row lg:flex-nowrap"
        >
          <button
            [ngClass]="{
              '!bg-[#727573]': isLoading === true,
              '!bg-[#019640]': isLoading === false,
              '!border-[#009456]': isLoading === false,
              '!border-[#727573]': isLoading === true
            }"
            [disabled]="isLoading"
            class="filled-button !w-full !text-base"
            type="submit"
          >
            {{ 'editProfile.cta' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
