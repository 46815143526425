import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '../../data.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-email-flow-content',
  templateUrl: './change-email-flow-content.component.html',
  styleUrls: ['./change-email-flow-content.component.css'],
})
export class ChangeEmailFlowContentComponent implements OnInit {
  @Input() user: User; // The type should match the expected data type
  step = 1;
  changeEmailForm: FormGroup;
  isLoading = false;

  constructor(
    public auth: AuthService,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private dataService: DataService,
    private router: Router
  ) {}
  ngOnInit() {
    this.changeEmailForm = this.formBuilder.group({
      currentEmail: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  verificationCodeStep() {
    const email = this.changeEmailForm.get('currentEmail').value;
    if (!this.validateEmail(email)) {
      this.translate
        .get('errors.invalidEmail')
        .subscribe((translatedError: string) => {
          this._snackBar.open(translatedError, '✖️', {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            panelClass: ['error-snackbar'],
          });
        });

      return;
    }
    this.sendVerificationCode(email, false);
  }

  sendVerificationCode(email: string, isResend: boolean) {
    this.isLoading = true;
    this.dataService
      .postData(environment.changeEmailEndpoint, { email })
      .subscribe({
        next: (response) => {
          if (!isResend) {
            this.step++;
          } else {
            this._snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              verticalPosition: 'top',
              politeness: 'assertive',
              data: {
                messageTranslationId: 'changeEmailFlow.codeResentFeedback',
              },
              panelClass: ['success-snackbar'],
            });
          }
          this.isLoading = false;
        },
        error: (error) => {
          this._snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            data: { messageTranslationId: 'error.failedEmailChange' },
            panelClass: ['success-snackbar'],
          });
          this.isLoading = false;
        },
      });
  }

  confirmChangeEmail() {
    this.isLoading = true;

    const code = this.changeEmailForm.get('code').value;
    const email = this.changeEmailForm.get('currentEmail').value;

    if (this.changeEmailForm.invalid || !code) {
      this.translate
        .get('errors.invalidCode')
        .subscribe((translatedError: string) => {
          this._snackBar.open(translatedError, '✖️', {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            panelClass: ['error-snackbar'],
          });
        });
      return;
    }

    const data = {
      code,
      email,
    };

    this.dataService
      .postData(environment.changeEmailConfirmEndpoint, data)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.step++;
        },
        error: (error) => {
          this._snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            data: { messageTranslationId: 'errors.failedEmailChange' },
            panelClass: ['error-snackbar'],
          });
          this.isLoading = false;
        },
      });
  }

  validateEmail(email) {
    const emailRegex =
      /^(?!.*\.{2})[a-zA-Z0-9._%+-]+(?:\.[a-zA-Z0-9._%+-]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  resendCode() {
    const email = this.changeEmailForm.get('currentEmail').value;
    this.sendVerificationCode(email, true);
  }

  goToLogin() {
    this.auth.logout();
  }

  handleBack() {
    // Handle back button click
    this.router.navigate(['/']);
  }
}
