<footer class="w-full">
  <div class="bg-appBgrd w-full py-14 md:py-24">
    <div
      class="flex flex-col gap-x-2 gap-y-8 md:flex-row sub-section-container"
    >
      <div
        class="flex flex-col md:items-start items-center md:w-1/2 gap-4 md:gap-8"
      >
        <h2 class="sub-section-header">
          {{ 'footer.lineOne' | translate }}
        </h2>
        <p class="sub-section-desc">
          {{ 'footer.lineTwo' | translate }}
        </p>
        <button
          class="outline-button !bg-appBgrd hover:!bg-primary-default text-lg"
          (click)="goToLandingPage()"
        >
          {{ 'footer.cta' | translate }}
        </button>
      </div>
      <div
        class="flex justify-center items-center gap-x-10 gap-y-6 md:w-1/2 flex-wrap"
      >
        <img
          src="../../assets/agrola-logo.svg"
          alt="logo"
          class="h-8 md:h-10"
        />
        <img
          src="../../assets/footer-icon-3.svg"
          alt="logo"
          class="h-8 md:h-10"
        />
      </div>
    </div>
  </div>
  <app-end-footer></app-end-footer>
</footer>
