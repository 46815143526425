import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';
import { SessionToken } from '../../components/verify-email/verify-email.component';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-progressive-profiling',
  templateUrl: './progressive-profiling.component.html',
  styleUrls: ['./progressive-profiling.component.css'],
})
export class ProgressiveProfilingComponent implements OnInit {
  state: string;
  landiSelected = false;
  landiName: string = '';
  step = 1;
  isSubmitted = false;
  sessionData: SessionToken | null = null;
  progressiveProfilingForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private languageService: LanguageService,
    private _snackBar: MatSnackBar
  ) {}

  decodeToken(token: string) {
    try {
      return jwtDecode<SessionToken>(token);
    } catch (Error) {
      return null;
    }
  }

  ngOnInit(): void {
    this.progressiveProfilingForm = this.formBuilder.group({
      landi: ['', Validators.required],
      customerNr: ['', Validators.required],
    });

    this.route.queryParams.subscribe((params) => {
      const sessionToken = params['session_token'];
      if (!sessionToken) {
        this.router.navigate(['/error']);
      }

      this.state = params['state'];
      if (!this.state) {
        this.router.navigate(['/error']);
      }

      this.sessionData = this.decodeToken(sessionToken);

      if (this.sessionData.error) {
        return this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          verticalPosition: 'top',
          politeness: 'assertive',
          data: { messageTranslationId: 'errors.company' },
          panelClass: ['error-snackbar'],
        });
      }
      this.languageService.setLanguage(
        this.sessionData.locale || this.languageService.getCurrentLanguage()
      );
    });
  }

  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }

  onLandiSelect(value: string) {
    this.landiSelected = !!value;
    if (this.landiSelected) {
      this.landiName =
        this.sessionData?.landis.find((landi) => landi.id == value)?.name || '';
    }
  }

  submitForm() {
    this.isSubmitted = true;
    if (this.progressiveProfilingForm.valid) {
      const data = btoa(JSON.stringify(this.progressiveProfilingForm.value));
      window.location.href = `${this.sessionData?.continue_uri}?state=${this.state}&data=${data}`;
    }
  }
  cancel() {
    window.location.href = `${this.sessionData?.continue_uri}?state=${this.state}&cancel=true`;
  }
}
