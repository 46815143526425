import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { jwtDecode } from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

type SessionToken = {
  continue_uri: string;
  locale: string;
  email: string;
  isResend: boolean;
  landis: { id: string; name: string }[];
  exp: number;
  iat: number;
  ip: string;
  iss: string;
  sub: string;
  error?: string;
  backUrl?: string;
  backUrlText?: string;
  clientName?: string;
  contacts?: { emailAddress: { address: string } }[];
  isOtpConfirmed: boolean;
  isOtpSent: boolean;
};

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css'],
})
export class OtpComponent implements OnInit {
  otpForm: FormGroup;
  isError: boolean = false;
  sessionData: SessionToken | null = null;
  state: string;
  maskedEmails: string[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private _snackBar: MatSnackBar,
    public translate: TranslateService
  ) {
    this.otpForm = this.fb.group({
      otpInput: [
        '',
        [Validators.required, Validators.maxLength(4), Validators.minLength(1)],
      ],
    });
    this.translate = translate;
  }

  decodeToken(token: string) {
    try {
      return jwtDecode<SessionToken>(token);
    } catch (Error) {
      return null;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const sessionToken = params['session_token'];
      if (!sessionToken) {
        this.router.navigate(['/error']);
      }

      this.state = params['state'];
      if (!this.state) {
        this.router.navigate(['/error']);
      }

      this.sessionData = this.decodeToken(sessionToken);

      if (this.sessionData?.error) {
        console.error('Session data error: ', this.sessionData.error);
      }

      // Extract and mask emails
      this.maskedEmails =
        this.sessionData?.contacts?.map((contact) =>
          this.maskEmail(contact.emailAddress.address)
        ) ?? [];

      const requestBody = {
        contacts: this.sessionData?.contacts,
      };

      this.dataService
        .postData(environment.generateOtpEndpoint, requestBody)
        .subscribe({
          next: (response: any) => {
            console.log('OTP sent successfully');
          },
          error: (error) => {
            console.error('Error sending OTP:', error);
            // this.isError = true;
          },
        });
    });
  }

  maskEmail(email: string): string {
    const [name, domain] = email.split('@');
    const maskedName = name.length > 1 ? name[0] + '*****' : '*****';
    return `${maskedName}@${domain}`;
  }

  verifyCode() {
    const otpValue = this.otpForm.get('otpInput')?.value;
    if (!otpValue) return;

    this.dataService
      .postData(environment.confirmOtpEndpoint, { inputVCode: otpValue })
      .subscribe({
        next: (response: any) => {
          if (response.isOtpValid) {
            console.log('OTP verified successfully.');
            this.router.navigate(['/']); // Redirect after successful verification
          } else {
            console.error('Invalid OTP');
            this.isError = true;
          }
        },
        error: (error) => {
          console.error('Error verifying OTP:', error);
          this.isError = true;
        },
      });
  }

  resendCode() {
    const requestBody = {
      contacts: this.sessionData?.contacts,
    };

    this.dataService
      .postData(environment.generateOtpEndpoint, requestBody)
      .subscribe({
        next: (response: any) => {
          console.log('OTP sent successfully');
          this.translate
            .get('otp.resend')
            .subscribe((translatedFeedback: string) => {
              this._snackBar.open(translatedFeedback, '✖️', {
                duration: 3000,
                verticalPosition: 'top',
                politeness: 'assertive',
                panelClass: ['success-snackbar'],
              });
            });
        },
        error: (error) => {
          console.error('Error sending OTP:', error);
          this.translate
            .get('otp.error')
            .subscribe((translatedFeedback: string) => {
              this._snackBar.open(translatedFeedback, '✖️', {
                duration: 3000,
                verticalPosition: 'top',
                politeness: 'assertive',
                panelClass: ['error-snackbar'],
              });
            });
          this.isError = true;
        },
      });
  }
}
