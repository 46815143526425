<div
  class="flex h-full flex-col items-center justify-center sm:justify-start bg-appBgrd p-4"
>
  <div class="card sm:mt-44 h-fit gap-4 flex flex-col">
    <div class="flex flex-col justify-center items-center mt-4">
      <img
        src="https://d1zl99f7e5jysh.cloudfront.net/image/992382361685/image_4fa9ebh3cl7cdf7lh3poum4o1s/-FPNG%20-S1000x200"
        width="124px"
        height="39px"
        alt="Fenaco"
      />
    </div>

    <div class="flex flex-col justify-start items-start mt-8 gap-4">
      <p class="text-2xl font-semibold text-[#334155]">
        {{ 'otp.heading' | translate }}
      </p>
      <p class="text-base font-light text-[#8E8E8A]">
        {{ 'otp.firstLine' | translate }}
      </p>
    </div>

    <div class="flex flex-col text-justify gap-0 p-0 m-0">
      <ul class="text-justify text-[#8E8E8A] relative">
        <li class="text-base font-light text-[#8E8E8A]">
          {{ 'otp.secondLine' | translate }}
        </li>

        <li
          class="text-base font-semibold text-[#8E8E8A]"
          *ngFor="let maskedEmail of maskedEmails"
        >
          {{ maskedEmail }}
        </li>
      </ul>

      <div class="flex flex-col w-full">
        <form [formGroup]="otpForm" (ngSubmit)="verifyCode()">
          <div class="relative">
            <input
              class="otp-input"
              id="otpInput"
              type="text"
              placeholder="{{ 'otp.placeholder' | translate }}"
              formControlName="otpInput"
              minlength="1"
              maxlength="4"
              [ngClass]="{ error: isError }"
            />
            <mat-icon class="error-icon" *ngIf="isError">error</mat-icon>
          </div>
          <p class="error-text" *ngIf="isError">
            {{ 'otp.error' | translate }}
          </p>
          <button class="mt-4" type="submit" [disabled]="otpForm.invalid">
            {{ 'otp.cta' | translate }}
          </button>
        </form>
      </div>
    </div>

    <button
      class="!text-base text-left underline !font-semibold !text-green-700 hover:underline !bg-transparent border-none p-0 m-0 cursor-pointer w-auto"
      (click)="resendCode()"
    >
      E-Mail erneut zusenden
    </button>
  </div>
</div>
