import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private defaultLang = 'de';
  constructor(private translate: TranslateService) {}

  initLanguage() {
    const savedLang = localStorage.getItem('selectedLangFenacoId');
    const browserLang = this.translate.getBrowserLang();
    const defaultLang =
      savedLang ||
      (this.translate.getLangs().includes(browserLang!) ? browserLang : 'de');
    this.translate.use(defaultLang);
  }

  setLanguage(lang: string) {
    // Ensure the language is not empty and is a valid language code
    const validLang =
      lang && this.translate.getLangs().includes(lang)
        ? lang
        : this.defaultLang;
    this.translate.use(validLang);
    localStorage.setItem('selectedLangFenacoId', validLang);
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }
}
