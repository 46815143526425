import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordContentComponent } from './components/change-password-content/change-password-content.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './pages/footer/footer.component';
import { ProfileContentComponent } from './components/profile-content/profile-content.component';
import { LoadingComponent } from './components/loading/loading.component';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import {
  TranslateModule,
  TranslateLoader,
  TranslatePipe,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProgressiveProfilingComponent } from './pages/progressive-profiling/progressive-profiling.component';
import { FooterContentComponent } from './components/footer-content/footer-content.component';
import { MatIconModule } from '@angular/material/icon';
import { OtpComponent } from './pages/otp/otp.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AboutFenacoIdComponent } from './pages/about-fenaco-id/about-fenaco-id.component';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChangeEmailFlowContentComponent } from './components/change-email-flow-content/change-email-flow-content.component';
import { ChangeEmailFlowComponent } from './pages/change-email-flow/change-email-flow.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AltNavBarComponent } from './components/alt-nav-bar/alt-nav-bar.component';
import { EndFooterComponent } from './components/end-footer/end-footer.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    OtpComponent,
    ProgressiveProfilingComponent,
    NavBarComponent,
    FooterComponent,
    ProfileContentComponent,
    LoadingComponent,
    ErrorComponent,
    EmailVerificationComponent,
    VerifyEmailComponent,
    ProgressiveProfilingComponent,
    LoginComponent,
    FooterContentComponent,
    ChangeEmailFlowComponent,
    ChangeEmailFlowContentComponent,
    ChangePasswordContentComponent,
    AboutFenacoIdComponent,
    ChangePasswordComponent,
    ImprintComponent,
    PrivacyPolicyComponent,
    AltNavBarComponent,
    EndFooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HighlightModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    SnackbarComponent,
    MatProgressBarModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ReactiveFormsModule,

    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        allowedList: [
          {
            // Match any request that starts 'https://{yourDomain}/api/v2/' (note the asterisk)
            uri: 'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api/*',
          },
          {
            uri: 'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api-uat/*',
          },
          {
            uri: 'https://europe-west6-prj-fenit-2827458368-ciamp.cloudfunctions.net/api-prd/*',
          },
          {
            uri: 'http://127.0.0.1:5001/fenaco-dev-pk/europe-west1/api/*',
          },
        ],
      },
      // httpInterceptor: {
      //   ...env.httpInterceptor,
      // },
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule, MatIconModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
    TranslatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
