import {
  Component,
  Input,
  OnInit,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from '../../data.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from 'src/app/services/language.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { jwtDecode } from 'jwt-decode';

export type SessionToken = {
  continue_uri: string;
  locale: string;
  email: string;
  isResend: boolean;
  landis: { id: string; name: string }[];
  exp: number;
  iat: number;
  ip: string;
  iss: string;
  sub: string;
  error?: string;
  backUrl?: string;
  backUrlText?: string;
  clientName?: string;
};

@Component({
  selector: 'app-change-password-content',
  templateUrl: './change-password-content.component.html',
  styleUrls: ['./change-password-content.component.css'],
})
export class ChangePasswordContentComponent implements OnInit {
  @Input() user: User;

  /**
   * We have exactly two inputs:
   *   1) currentPassword
   *   2) newPassword
   */
  changePasswordForm: FormGroup;

  // For toggling input visibility
  hideCurrentPassword = true;
  hideNewPassword = true;

  // Once the user starts typing in newPassword, we show the bullet box
  showRequirements = false;

  // Whether an API call is in progress
  isLoading = false;
  sessionData: SessionToken | null = null;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private languageService: LanguageService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
    });

    this.route.queryParams.subscribe((params) => {
      const sessionToken = params['session_token'];
      // if (!sessionToken) {
      //   this.router.navigate(['/error']);
      // }

      this.sessionData = this.decodeToken(sessionToken);
      this.languageService.setLanguage(
        this.sessionData.locale || this.languageService.getCurrentLanguage()
      );
    });
  }

  decodeToken(token: string) {
    try {
      return jwtDecode<SessionToken>(token);
    } catch (Error) {
      return null;
    }
  }

  /**
   * Called when user types in newPassword. We switch on the requirement box
   * as soon as there's any text.
   */
  onNewPasswordInput(): void {
    const newVal = this.changePasswordForm.get('newPassword')?.value || '';
    this.showRequirements = newVal.length > 0;
  }

  /**
   * Bullet-point checks:
   *   "Mindestens 12 Zeichen"
   *   "Mindestens 3 von: (a-z, A-Z, 0-9, special char)"
   */
  get meetsMinLength(): boolean {
    const pw = this.changePasswordForm.get('newPassword')?.value || '';
    return pw.length >= 12;
  }
  get hasLowerCase(): boolean {
    const pw = this.changePasswordForm.get('newPassword')?.value || '';
    return /[a-z]/.test(pw);
  }
  get hasUpperCase(): boolean {
    const pw = this.changePasswordForm.get('newPassword')?.value || '';
    return /[A-Z]/.test(pw);
  }
  get hasDigit(): boolean {
    const pw = this.changePasswordForm.get('newPassword')?.value || '';
    return /\d/.test(pw);
  }
  get hasSpecialChar(): boolean {
    const pw = this.changePasswordForm.get('newPassword')?.value || '';
    return /[!@#$%^&*()_+\-={};':"|,.<>?]/.test(pw);
  }
  get meets3of4Sets(): boolean {
    let setsUsed = 0;
    if (this.hasLowerCase) {
      setsUsed++;
    }
    if (this.hasUpperCase) {
      setsUsed++;
    }
    if (this.hasDigit) {
      setsUsed++;
    }
    if (this.hasSpecialChar) {
      setsUsed++;
    }
    return setsUsed >= 3;
  }

  /**
   * If user pastes directly into newPassword, we want to show the requirement box
   * and reevaluate bullet points
   */
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const text = event.clipboardData?.getData('text') || '';
    this.changePasswordForm.get('newPassword')?.setValue(text);
    this.showRequirements = text.length > 0;
  }

  /**
   * Called when user clicks "Bestätigen"
   * We must ensure:
   *   1) currentPassword is not empty
   *   2) newPassword is not empty
   *   3) newPassword != currentPassword
   *   4) meetsMinLength + meets3of4Sets
   */
  saveChanges(): void {
    this.isLoading = true;

    const currentPw =
      this.changePasswordForm.get('currentPassword')?.value ?? '';
    const newPw = this.changePasswordForm.get('newPassword')?.value ?? '';

    // Basic validations
    if (!currentPw) {
      this.showErrorSnack('errors.currentPassword');
      this.isLoading = false;
      return;
    }
    if (!newPw) {
      this.showErrorSnack('errors.newPassword');
      this.isLoading = false;
      return;
    }
    if (newPw === currentPw) {
      // Typically we disallow the same password
      this.translate.get('errors.passwordSameAsOld').subscribe((txt) => {
        this.snackBar.open(txt, '✖️', {
          duration: 3000,
          verticalPosition: 'top',
          panelClass: ['error-snackbar'],
        });
      });
      this.isLoading = false;
      return;
    }
    if (!this.meetsMinLength || !this.meets3of4Sets) {
      this.showErrorSnack('errors.passwordCriteriaNotMet');
      this.isLoading = false;
      return;
    }

    // If all good => call your backend
    const data = {
      email: this.user?.email,
      currentPassword: currentPw,
      newPassword: newPw,
      newPasswordConfirm: newPw,
    };

    this.dataService
      .postData(environment.changePasswordEndpoint, data)
      .subscribe({
        next: () => {
          // success
          this.translate
            .get('changePassword.passwordUpdated')
            .subscribe((txt) => {
              this.snackBar.open(txt, '✖️', {
                duration: 3000,
                verticalPosition: 'top',
                panelClass: ['success-snackbar'],
              });
            });
          this.isLoading = false;
          // e.g. redirect or show success message
          // this.router.navigate(['/dashboard']); // if desired
        },
        error: () => {
          // error
          this.translate.get('errors.passwordChangeError').subscribe((txt) => {
            this.snackBar.open(txt, '✖️', {
              duration: 3000,
              verticalPosition: 'top',
              panelClass: ['error-snackbar'],
            });
          });
          this.isLoading = false;
        },
      });
  }

  /**
   * Convenience method to show error using your custom SnackbarComponent
   * with a given translation key
   */
  private showErrorSnack(translationKey: string) {
    this.translate.get(translationKey).subscribe((translated: string) => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        verticalPosition: 'top',
        data: { messageTranslationId: translationKey },
        panelClass: ['error-snackbar'],
      });
    });
  }

  getLogo(): string {
    if (
      this.sessionData?.clientName === 'WSA' ||
      this.sessionData?.clientName === 'WSA-POC'
    ) {
      return '../../../assets/footer-icon-3.svg';
    } else if (
      this.sessionData?.clientName === 'ECS' ||
      this.sessionData?.clientName === 'AOP-POC'
    ) {
      return '../../../assets/agrola-logo.svg';
    } else {
      return 'https://d1zl99f7e5jysh.cloudfront.net/image/992382361685/image_segleuhno14kvbimin4o22570c/-FPNG%20-S1000x200';
    }
  }
}
