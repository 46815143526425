import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Component({
  selector: 'app-alt-nav-bar',
  templateUrl: './alt-nav-bar.component.html',
  styleUrl: './alt-nav-bar.component.css',
})
export class AltNavBarComponent  {
  owner$: Observable<boolean>;

  constructor(
    public auth: AuthService,
  ) {
    this.owner$ = this.auth.user$.pipe(
      filter((user) => user !== null), // Filter out null values if needed
      map((user) => user['https://www.fenaco.com/poc/roles'].includes('Owner'))
    );
  }
}
