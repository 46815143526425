<div id="#navbar" class="w-full px-12 py-8 transition-all bg-white">
  <div
    class="content-max-width flex flex-row w-full items-center justify-between gap-4 sm:gap-4 flex-wrap"
  >
    <img
      src="../../assets/fenaco_LandingPage_Logo.svg"
      width="265px"
      height="110px"
      alt="Fenaco logo"
      class="sm:w-64 w-28"
    />
    <a routerLink="/" class="sm:ml-auto flex justify-center items-center">
      <picture>
        <source
          srcset="
            https://d1zl99f7e5jysh.cloudfront.net/image/992382361685/image_segleuhno14kvbimin4o22570c/-FPNG%20-S1000x200
          "
          media="(min-width: 640px)"
        />
        <img
          src="../../assets/fenaco-logo-mobile.svg"
          alt="Fenaco logo"
          class="max-h-[42px]"
        />
      </picture>
    </a>
  </div>
</div>
