<div
  class="flex flex-col items-center justify-center sm:justify-start bg-appBgrd pt-12 sm:py-28"
>
  <form
    class="bg-white changeFormContainer sm:rounded-lg sm:shadow-md overflow-hidden w-full sm:w-fit"
    [formGroup]="changeEmailForm"
  >
    <div class="flex flex-col max-w-md mx-auto bg-white py-12 px-8">
      <div class="flex text-center justify-center items-center pt-3 pb-3 mb-5">
        <img
          src="https://d1zl99f7e5jysh.cloudfront.net/image/992382361685/image_segleuhno14kvbimin4o22570c/-FPNG%20-S1000x200"
          width="198px"
          height="46px"
          alt="Fenaco"
        />
      </div>
      <h1 class="text-gray600 text-2xl font-semibold text-left mb-5">
        {{ 'changeEmailFlow.title' | translate }}
      </h1>
      <div
        class="mt-2 leading-5 text-base font-light text-[#8E8E8A] mb-5"
        [ngClass]="{ hidden: step !== 1, flex: step === 1 }"
      >
        {{ 'changeEmailFlow.description' | translate }}
      </div>
      <div
        class="mt-2 leading-5 text-base font-light text-[#8E8E8A] mb-5"
        [ngClass]="{ hidden: step !== 2, flex: step === 2 }"
      >
        {{ 'changeEmailFlow.codeDescription' | translate }}
      </div>
      <div
        class="mt-2 leading-5 text-base font-light text-[#8E8E8A] flex-col mb-5"
        [ngClass]="{ hidden: step !== 3, flex: step === 3 }"
      >
        {{ 'changeEmailFlow.emailChangedFeedback' | translate }}
        <a (click)="goToLogin()" class="resendLink mt-2">{{
          'changeEmailFlow.goToLogin' | translate
        }}</a>
      </div>
      <div [ngClass]="{ hidden: step !== 1, flex: step === 1 }">
        <mat-form-field class="w-full">
          <mat-label>{{ 'changeEmailFlow.placeholder' | translate }}</mat-label>
          <mat-icon class="text-gray300" matPrefix>email</mat-icon>
          <input
            matInput
            [disabled]="step !== 1"
            id="currentEmail"
            required="true"
            type="email"
            placeholder="{{ 'changeEmailFlow.placeholder' | translate }}"
            formControlName="currentEmail"
            name="currentEmail"
            [email]="true"
          />
        </mat-form-field>
      </div>
      <div [ngClass]="{ hidden: step !== 2, flex: step === 2 }">
        <mat-form-field class="w-full">
          <mat-icon class="text-gray300" matPrefix>password</mat-icon>
          <input
            matInput
            [disabled]="step !== 2"
            id="code"
            required="true"
            type="text"
            placeholder="{{ 'changeEmailFlow.code' | translate }}"
            formControlName="code"
            name="code"
          />
        </mat-form-field>
      </div>
      <div
        class="flex w-full gap-2 justify-center flex-col-reverse flex-wrap lg:flex-row lg:flex-nowrap"
        [ngClass]="{ hidden: step !== 1, flex: step === 1 }"
      >
        <button
          class="outline-button !w-full lg:!w-1/2 !border-gray300 !text-gray500 hover:!bg-inherit !hover:text-inherit !text-base"
          (click)="handleBack()"
        >
          {{ 'changePassword.backCta' | translate }}
        </button>
        <button
          [ngClass]="{
            '!bg-[#727573]': isLoading === true,
            '!bg-[#019640]': isLoading === false,
            '!border-[#009456]': isLoading === false,
            '!border-[#727573]': isLoading === true
          }"
          [disabled]="isLoading"
          (click)="verificationCodeStep()"
          class="filled-button !w-full lg:!w-1/2 !text-base"
        >
          {{ 'changeEmailFlow.cta' | translate }}
        </button>
      </div>
      <div
        class="flex flex-col gap-2 mt-2 mb-2 leading-5"
        [ngClass]="{ hidden: step !== 2, flex: step === 2 }"
      >
        <button
          [ngClass]="{
            '!bg-[#727573]': isLoading === true,
            '!bg-[#019640]': isLoading === false,
            '!border-[#009456]': isLoading === false,
            '!border-[#727573]': isLoading === true
          }"
          [disabled]="isLoading"
          (click)="confirmChangeEmail()"
          class="filled-button !w-full !text-base"
        >
          {{ 'changeEmailFlow.confirmCta' | translate }}
        </button>
      </div>
      <div
        class="flex flex-col gap-2 mt-2 mb-2 leading-5 text-base text-[#8E8E8A]"
        [ngClass]="{ hidden: step !== 2, flex: step === 2 }"
      >
        <a (click)="resendCode()" class="resendLink">{{
          'changeEmailFlow.resendCode' | translate
        }}</a>
      </div>
    </div>
  </form>
</div>
