<app-alt-nav-bar></app-alt-nav-bar>
<section
  id="#scroll-section"
  class="w-screen flex flex-col justify-center items-center text-primary-default"
>
  <div class="w-full py-14 md:py-24">
    <div
      class="flex flex-col gap-10 justify-center items-center sub-section-container"
    >
      <h2 class="pageHeader">
        {{ 'landingPage.firstSection.title' | translate }}
      </h2>
      <div
        class="flex flex-col w-full justify-center items-center text-content-container"
      >
        <p class="pageParagraph font-light">
          {{ 'landingPage.firstSection.description' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="w-full py-14 md:py-24">
    <div
      class="flex flex-col md:flex-row w-full justify-between items-start gap-10 md:gap-24 sub-section-container"
    >
      <div class="flex flex-col w-full items-center md:items-start gap-2">
        <img src="../../assets/smile-icon.svg" alt="icon" class="h-12 w-12" />
        <p
          class="text-center md:text-left font-semibold text-xl text-[#009456] w-full"
        >
          {{ 'landingPage.secondSection.benefits.benefit1.title' | translate }}
        </p>
        <p class="feature-text-layout">
          {{
            'landingPage.secondSection.benefits.benefit1.description'
              | translate
          }}
        </p>
      </div>
      <div class="flex flex-col w-full items-center md:items-start gap-2">
        <img src="../../assets/lock-icon.svg" alt="icon" class="h-12 w-12" />
        <p
          class="text-center md:text-left font-semibold text-xl text-[#009456] w-full"
        >
          {{ 'landingPage.secondSection.benefits.benefit2.title' | translate }}
        </p>
        <p class="feature-text-layout">
          {{
            'landingPage.secondSection.benefits.benefit2.description'
              | translate
          }}
        </p>
      </div>
      <div class="flex flex-col w-full items-center md:items-start gap-2">
        <img src="../../assets/cube-icon.svg" alt="icon" class="h-12 w-12" />
        <p
          class="text-center md:text-left font-semibold text-xl text-[#009456] w-full"
        >
          {{ 'landingPage.secondSection.benefits.benefit3.title' | translate }}
        </p>
        <p class="feature-text-layout">
          {{
            'landingPage.secondSection.benefits.benefit3.description'
              | translate
          }}
        </p>
      </div>
    </div>
  </div>
  <div class="bg-appBgrd w-full py-14 md:py-24">
    <div
      class="flex flex-col gap-x-2 gap-y-8 md:flex-row sub-section-container"
    >
      <div
        class="flex flex-col md:items-start items-center md:w-1/2 gap-4 md:gap-8"
      >
        <h2 class="sub-section-header">
          {{ 'landingPage.thirdSection.title' | translate }}
        </h2>
        <p class="sub-section-desc">
          {{ 'landingPage.thirdSection.description' | translate }}
        </p>
      </div>
      <div
        class="flex justify-center items-center gap-x-10 gap-y-6 md:w-1/2 flex-wrap"
      >
        <img
          src="../../assets/agrola-logo.svg"
          alt="logo"
          class="h-8 md:h-10"
        />
        <img
          src="../../assets/footer-icon-3.svg"
          alt="logo"
          class="h-8 md:h-10"
        />
      </div>
    </div>
  </div>
  <div class="w-full py-14 md:py-24">
    <div
      class="flex flex-col gap-x-2 gap-y-8 md:flex-row sub-section-container"
    >
      <div
        class="flex flex-col md:items-start items-center md:w-1/2 gap-4 md:gap-8"
      >
        <h2 class="sub-section-header">
          {{ 'landingPage.forthSection.title' | translate }}
        </h2>
        <p class="sub-section-desc">
          {{ 'landingPage.forthSection.description' | translate }}
        </p>
        <button class="outline-button text-lg">
          {{ 'landingPage.forthSection.cta' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="flex h-full flex-col md:justify-start bg-[#fff]">
    <button (click)="scrollTop()" class="scrollToTop">
      <img
        src="../../assets/top.svg"
        width="50px"
        height="50px"
        alt="Fenaco logo"
      />
    </button>
  </div>
</section>
<app-end-footer></app-end-footer>
