<div class="flex max-h-full" *ngIf="auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
</div>

<ng-template #loaded>
  <app-change-password-content
    *ngIf="profile"
    [user]="profile"
  ></app-change-password-content>
</ng-template>
