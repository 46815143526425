<div class="w-full bg-white px-12 py-8">
  <div
    class="flex flex-row justify-center md:justify-between gap-12 flex-wrap content-max-width"
  >
    <div
      class="flex justify-center items-center gap-x-10 gap-y-4 text-lg text-[#009456] font-light flex-wrap"
    >
      <a
        class="hover:underline hover:underline-offset-[6px]"
        routerLink="/imprint"
        routerLinkActive="font-semibold"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ 'footer.impressum' | translate }}</a
      >
      <a
        class="hover:underline hover:underline-offset-[6px]"
        routerLink="/privacy"
        routerLinkActive="font-semibold"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ 'footer.privacyPolicy' | translate }}</a
      >
    </div>
    <div
      class="flex items-center justify-end flex-wrap gap-6 text-lg text-[#009456] font-light"
    >
      <img src="../../assets/globe-icon.svg" alt="globe-icon" class="max-h-6" />
      <button
        (click)="switchLanguage('de')"
        [class.font-semibold]="currentLang === 'de'"
        class="hover:underline hover:underline-offset-[6px]"
      >
        DE
      </button>
      <button
        (click)="switchLanguage('fr')"
        [class.font-semibold]="currentLang === 'fr'"
        class="hover:underline hover:underline-offset-[6px]"
      >
        FR
      </button>
    </div>
  </div>
</div>
