import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-end-footer',
  templateUrl: './end-footer.component.html',
  styleUrl: './end-footer.component.css',
})
export class EndFooterComponent implements OnInit {
  currentLang: string;

  constructor(
    public translate: TranslateService,
    private LanguageService: LanguageService
  ) {
    this.currentLang = this.LanguageService.getCurrentLanguage();
  }

  ngOnInit() {}

  switchLanguage(lang: string) {
    this.LanguageService.setLanguage(lang);
    this.currentLang = lang;
  }
}
