<div
  class="flex flex-col items-center justify-center sm:justify-start bg-appBgrd pt-12 sm:py-28"
>
  <form
    class="bg-white changeFormContainer sm:rounded-lg sm:shadow-md overflow-hidden w-full sm:w-fit"
    [formGroup]="progressiveProfilingForm"
    (ngSubmit)="submitForm()"
  >
    <div class="flex flex-col max-w-md mx-auto bg-white py-12 px-8">
      <div class="flex text-center justify-center items-center pt-3 pb-3">
        <img
          src="https://d1zl99f7e5jysh.cloudfront.net/image/992382361685/image_4fa9ebh3cl7cdf7lh3poum4o1s/-FPNG%20-S1000x200"
          width="124px"
          height="39px"
          alt="Fenaco"
        />
      </div>
      <h1 class="mt-2 text-2xl leading-8 font-bold">
        {{ 'progressiveProfiling.step1.title' | translate }}
        <span>{{ this.step }}/2</span>
      </h1>
      <p
        class="py-4 leading-5 text-base font-light text-[#8E8E8A]"
        [ngClass]="{ hidden: step === 2, flex: step === 1 }"
      >
        {{ 'progressiveProfiling.step1.description' | translate }}
      </p>
      <div
        class="relative flex"
        [ngClass]="{ hidden: step === 2, flex: step === 1 }"
      >
        <mat-form-field class="w-full">
          <mat-select
            name="landi"
            formControlName="landi"
            (selectionChange)="onLandiSelect($event.value)"
            required="true"
          >
            <mat-option value="" disabled>{{
              'progressiveProfiling.selectLandi' | translate
            }}</mat-option>
            <ng-container *ngFor="let landi of sessionData?.landis">
              <mat-option [value]="landi.id">{{ landi.name }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        class="flex flex-row w-full justify-start items-start -mt-4"
        [ngClass]="{ hidden: step === 2, flex: step === 1 }"
      >
        <p class="mt-2 leading-5 text-base font-light text-[#8E8E8A]">
          {{ 'progressiveProfiling.step1.whatIsLandi' | translate }}
        </p>
        <button
          mat-raised-button
          [matTooltipPosition]="'below'"
          matTooltip="{{
            'progressiveProfiling.step1.whatIsLandiInfo' | translate
          }}"
          aria-label="Button that displays a tooltip when focused or hovered over"
        >
          <mat-icon class="info-landi-icon">info_outline</mat-icon>
        </button>
      </div>

      <div
        class="my-2 leading-5 text-base text-[#8E8E8A]"
        [ngClass]="{ hidden: step === 2, flex: step === 1 }"
      >
        <button
          class="filled-button !w-full !text-base"
          [disabled]="!landiSelected"
          (click)="nextStep()"
        >
          {{ 'progressiveProfiling.cta' | translate }}
        </button>
      </div>
      <!-- Start of second Step -->
      <p
        class="py-4 leading-5 text-base font-light text-[#8E8E8A]"
        [ngClass]="{ hidden: step === 1, flex: step === 2 }"
      >
        {{
          'progressiveProfiling.step2.description'
            | translate : { landi_name: landiName }
        }}
      </p>
      <div
        class="relative flex"
        [ngClass]="{ hidden: step === 1, flex: step === 2 }"
      >
        <mat-form-field class="w-full">
          <input
            matInput
            id="customerNr"
            required="true"
            type="text"
            placeholder="{{
              'progressiveProfiling.step2.customerNr.placeholder' | translate
            }}"
            name="customerNr"
            formControlName="customerNr"
          />
        </mat-form-field>
      </div>
      <div
        class="flex flex-row w-full justify-start items-start -mt-4"
        [ngClass]="{ hidden: step === 1, flex: step === 2 }"
      >
        <p class="mt-2 leading-5 text-base font-light text-[#8E8E8A]">
          {{ 'progressiveProfiling.step2.whereToFind' | translate }}
        </p>
        <button
          mat-raised-button
          [matTooltipPosition]="'below'"
          matTooltip="{{
            'progressiveProfiling.step2.whereToFindInfo' | translate
          }}"
          aria-label="Button that displays a tooltip when focused or hovered over"
        >
          <mat-icon class="info-landi-icon">info_outline</mat-icon>
        </button>
      </div>

      <div
        class="flex flex-row gap-2 mt-2 mb-2 leading-5 text-base text-[#8E8E8A]"
        [ngClass]="{ hidden: step === 1, flex: step === 2 }"
      >
        <button
          class="outline-button !w-full lg:!w-1/2 !border-gray300 !text-gray500 hover:!bg-inherit !hover:text-inherit !text-base"
          (click)="prevStep()"
        >
          {{ 'progressiveProfiling.step2.backCta' | translate }}
        </button>
        <button
          type="submit"
          class="filled-button !w-full lg:!w-1/2 !text-base"
          [disabled]="!progressiveProfilingForm.valid && isSubmitted"
        >
          {{ 'progressiveProfiling.step2.cta' | translate }}
        </button>
      </div>
      <a
        (click)="cancel()"
        class="outline-button !w-full !border-gray300 !text-gray500 hover:!bg-inherit !hover:text-inherit !text-base"
        >{{ 'progressiveProfiling.cancelLabel' | translate }}</a
      >
    </div>
  </form>
</div>
