import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer-content',
  templateUrl: './footer-content.component.html',
  styleUrls: ['./footer-content.component.css'],
})
export class FooterContentComponent implements OnInit {
  constructor(
    public auth: AuthService,
    private router: Router,
    public translate: TranslateService
  ) {}

  ngOnInit() {}

  goToLandingPage() {
    this.router.navigate(['/about-fenaco-login']);
  }
}
