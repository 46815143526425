<div class="flex justify-center bg-gray-50 py-10 sm:py-20">
  <form
    class="bg-white w-full max-w-sm px-8 py-10 rounded-lg shadow"
    [formGroup]="changePasswordForm"
    (ngSubmit)="saveChanges()"
  >
    <!-- Top label: "Zugang mit fenaco Login" (if desired) -->
    <!-- Second Row: Title -->
    <div class="flex text-center justify-center items-center">
      <img [src]="getLogo()" class="h-8 md:h-10" alt="Logo" />
    </div>

    <!-- Third Row: Bold Text -->
    <div class="mt-8 mb-8 text-2xl leading-8">
      <p class="font-semibold">{{ 'changePassword.title' | translate }}</p>
    </div>

    <!-- 1) Aktuelles Passwort -->
    <div class="mb-5 relative">
      <label class="block text-sm font-normal text-[#242424] mb-1">
        {{ 'changePassword.currentPassword' | translate }}*
      </label>
      <input
        [type]="hideCurrentPassword ? 'password' : 'text'"
        formControlName="currentPassword"
        class="block w-full border border-gray-300 text-base text-[#242424] rounded py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#019640]"
        placeholder="{{ 'changePassword.currentPassword' | translate }}"
        required
      />
      <!-- Eye icon toggle for current password -->
      <button
        type="button"
        class="absolute right-3 top-9 text-gray-400"
        (click)="hideCurrentPassword = !hideCurrentPassword"
      >
        <mat-icon>
          {{ hideCurrentPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </button>
    </div>

    <!-- 2) Neues Passwort -->
    <div class="mb-5 relative">
      <label class="block text-sm font-normal text-[#242424] mb-1">
        {{ 'changePassword.newPassword' | translate }}*
      </label>
      <input
        [type]="hideNewPassword ? 'password' : 'text'"
        formControlName="newPassword"
        (input)="onNewPasswordInput()"
        (paste)="onPaste($event)"
        class="block w-full border border-gray-300 text-base text-[#242424] rounded py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#019640]"
        placeholder="{{ 'changePassword.newPassword' | translate }}"
        required
      />
      <!-- Eye icon toggle for new password -->
      <button
        type="button"
        class="absolute right-3 top-9 text-gray-400"
        (click)="hideNewPassword = !hideNewPassword"
      >
        <mat-icon>
          {{ hideNewPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </button>
    </div>

    <!-- Requirements box: only visible after user typed at least 1 char in newPassword -->
    <div
      class="border border-gray-300 rounded-md p-4 mb-6"
      *ngIf="showRequirements"
    >
      <p class="mb-3 font-medium text-base text-[#242424]">
        {{ 'changePassword.passwordStrengthTitle' | translate }}
      </p>
      <ul class="list-none space-y-2 text-sm text-[#242424]">
        <!-- 1) Mindestens 12 Zeichen -->
        <li class="flex items-center">
          <mat-icon *ngIf="meetsMinLength" class="mr-2" style="color: #019640"
            >check</mat-icon
          >
          <span
            [ngClass]="{
              'text-[#019640]': meetsMinLength,
              'text-[#242424]': !meetsMinLength
            }"
          >
            {{ 'changePassword.passwordStrengthRequirement1' | translate }}
          </span>
        </li>

        <!-- 2) Mindestens 3 von: (Kleinbuchst, Grossbuchst, Ziffer, Sonderz) -->
        <li>
          <div class="flex items-center">
            <mat-icon *ngIf="meets3of4Sets" class="mr-2" style="color: #019640"
              >check</mat-icon
            >
            <span
              [ngClass]="{
                'text-[#019640]': meets3of4Sets,
                'text-[#242424]': !meets3of4Sets
              }"
            >
              {{ 'changePassword.passwordStrengthRequirement6' | translate }}
            </span>
          </div>
          <ul class="ml-7 mt-2 space-y-1">
            <li class="flex items-center">
              <mat-icon *ngIf="hasLowerCase" class="mr-1" style="color: #019640"
                >check</mat-icon
              >
              <span
                [ngClass]="{
                  'text-[#019640]': hasLowerCase,
                  'text-[#242424]': !hasLowerCase
                }"
              >
                {{ 'changePassword.passwordStrengthRequirement2' | translate }}
              </span>
            </li>
            <li class="flex items-center">
              <mat-icon *ngIf="hasUpperCase" class="mr-1" style="color: #019640"
                >check</mat-icon
              >
              <span
                [ngClass]="{
                  'text-[#019640]': hasUpperCase,
                  'text-[#242424]': !hasUpperCase
                }"
              >
                {{ 'changePassword.passwordStrengthRequirement3' | translate }}
              </span>
            </li>
            <li class="flex items-center">
              <mat-icon *ngIf="hasDigit" class="mr-1" style="color: #019640"
                >check</mat-icon
              >
              <span
                [ngClass]="{
                  'text-[#019640]': hasDigit,
                  'text-[#242424]': !hasDigit
                }"
              >
                {{ 'changePassword.passwordStrengthRequirement4' | translate }}
              </span>
            </li>
            <li class="flex items-center">
              <mat-icon
                *ngIf="hasSpecialChar"
                class="mr-1"
                style="color: #019640"
                >check</mat-icon
              >
              <span
                [ngClass]="{
                  'text-[#019640]': hasSpecialChar,
                  'text-[#242424]': !hasSpecialChar
                }"
              >
                {{ 'changePassword.passwordStrengthRequirement5' | translate }}
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- The big green "Bestätigen" button -->
    <button
      type="submit"
      class="w-full bg-[#019640] hover:bg-[#019640]/90 text-white rounded-lg py-3 text-base font-semibold"
      [disabled]="isLoading"
    >
      {{ 'changePassword.cta' | translate }}
    </button>
  </form>
</div>
