<app-alt-nav-bar></app-alt-nav-bar>
<section
  id="#scroll-section"
  class="w-screen flex flex-col justify-center items-center text-primary-default"
>
  <div class="w-full py-14 md:py-24">
    <div
      class="w-full flex flex-col gap-10 justify-center items-center sub-section-container"
    >
      <h2 class="pageHeader">{{ 'imprintPage.title' | translate }}</h2>
      <div
        class="flex flex-col w-full justify-center items-start text-content-container"
      >
        <p class="pageParagraph font-light">
          {{ 'imprintPage.paragraphOne' | translate }}
        </p>
        <p class="pageParagraph font-light">
          {{ 'imprintPage.paragraphTwo' | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
<app-end-footer></app-end-footer>
